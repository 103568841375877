<template>
  <div>
    <!-- width="385" -->
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series"
      width="380"
      ref="orderChart"
    ></apexchart>
  </div>
</template>


<script>
export default {
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 280,
          type: "pie",
          offsetX: 60,
        },
        labels: ["Completed", "Processing", "Cancelled"],
        colors: ["#816BFF", "#13C9F2", "#F94B4B"],
        responsive: [
          {
            breakpoint: 1500,
            options: {
              chart: {
                width: "100%",
                height: "250px",
                offsetX: 0,
              },
              legend: {
                // position: "bottom",
                show: false,
              },
            },
          },
          {
            breakpoint: 1700,
            options: {
              chart: {
                width: "380px",
                height: "250px",
                offsetX: 0,
              },
              legend: {
                // position: "top",
                show: true,
              },
            },
          },
        ],
        legend: {
          position: "left",
          offsetY: 25,
          offsetX: -30,
          height: 630,
          itemMargin: {
            vertical: 20,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      },
    };
  },
  methods: {
    // Get order statistics
    getorder() {
      axios.get("supports/order_statistics/").then((response) => {
        if (response.data.success) {
          let { processing, completed, cancelled } = response.data;
          this.$refs.orderChart.updateOptions({
            series: [completed, processing, cancelled],
          });
        }
      });
    },
  },
  mounted() {
    this.getorder();
  },
};
</script>

<style>
</style>